import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../styles/Hero"

const ContactPage = ({}: PageProps) => (
  <Layout>
    <SEO title="Contact" />

    <Hero
      emoji="🚧 👷‍♂️"
      title="Let's Talk!"
      content={
        <>
          <p>
            I've got a fancy contact form in the works, but it's not quite ready
            yet.
          </p>

          <p>
            In the meantime, you can reach me at:{" "}
            <a href="mailto:ryan@mranderson.io">ryan@mranderson.io</a>
          </p>
        </>
      }
    />
  </Layout>
)

export default ContactPage
